/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout } from "../../layout";
import { socket } from "../../utils/socket";
import { useParams, useSearchParams } from "react-router-dom";
import { setToStorage } from "../../constant/storage";
import { STORAGE_KEYS } from "../../constant/storageKeys";
import { successToast } from "../../constant/toast";
import { GoogleMap, MarkerF, PolylineF } from "@react-google-maps/api";

import axios from "axios";
import { generateSEKAndHash } from "../../utils/crypto";
import { log } from "console";

type Geometry = {
  lat: number;
  lng: number;
  completeAddress?: string;
  isCurrent?: boolean;
};

const customPickupIcon = {
  url: `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`, // Blue marker icon for pickup
};

const customDriverIcon = {
  url: `http://maps.google.com/mapfiles/ms/icons/red-dot.png`, // Red marker icon for driver
};
const mapContainerStyle = {
  height: "400px",
  width: "600px",
};
const urlParams = new URLSearchParams(window.location.search);
const Id = urlParams.get("bookingId"); // Get token from URL

const prepHeaders = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("accessToken");
  const encryptData = generateSEKAndHash(token);
  if (encryptData) {
    return {
      hash: encryptData.hash,
      sek: encryptData.sek,
      deviceType: "android",
    };
  }

  return {};
};

const Tracking = () => {
  const [socketStatus, setSocketStatus] = useState<any>();
  const [orderStatus, setOrderStatus] = useState(0);
  const [driverLocation, setDriverLocation] = useState<any>({
    latitude: 0,
    longitude: 0,
  });
  const [details, setDetails] = useState<any>();
  const [bookingType, setBookingType] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const accessToken = searchParams.get("accessToken");
  const bookingId = searchParams.get("bookingId");

  const [isPickUpComplete, setIsPickUpComplete] = useState<boolean>(false);
  const [pickupLocation, setPickupLocation] = useState<any>({
    latitude: 0,
    longitude: 0,
  });

  const [pickUpDummy, setPickUpDummy] = useState<any>({
    latitude: 0,
    longitude: 0,
  });

  const [dropOffDummy, setDropOffDummy] = useState<any>({
    latitude: 0,
    longitude: 0,
  });
  const [initialData, setInitialData] = useState<any>();

  const [routeCoordinates, setRouteCoordinates] = useState<Geometry[]>([]);
  const [initialRouteCoordinates, setInitialRouteCoordinates] = useState<
    Geometry[]
  >([]);

  const [dropofLocation, setDropofLocation] = useState<any>({
    latitude: 0,
    longitude: 0,
  });
  const mapRef = useRef<any>(null);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const instance = axios.create({
    baseURL: `https://esqyreapi.appgrowthcompany.com/operational/api/v1/ClientOrder/booking/${bookingId}`,

    headers: prepHeaders(),
  });
  console.log(orderStatus, "orderStatus");

  console.log(driverLocation, "driverLocation");
  // console.log(pickupLocation, "pickupLocation");
  console.log(dropofLocation, "dropofLocation");
  console.log(dropOffDummy, "dropOffDummy");
  console.log(pickUpDummy, "pickUpDummy");

  const title = (() => {
    switch (socketStatus > 0 ? socketStatus : initialData?.orderStatus) {
      case 2:
        return "Chauffeur on way";
      case 3:
        return "Chauffeur is near by your location";
      case 4:
        return "Chauffeur has arrived at your location";
      case 5:
        return "Pickup completed";
      case 6:
        successToast("Your pickup is incomplete");
        return "Your pickup is incomplete";
      case 7:
        return "Your ride has been started";
      case 8:
        successToast("Ride is Completed");
        return "Ride is Completed";
      case 21:
        successToast("Ride is Completed");
        return "Ride is Completed";
      case 9:
        return "Your valet is assigned";
      case 10:
        return "Your valet is on the way for Drop-off";
      case 11:
        return "Your valet is near by location";
      case 12:
        successToast("Your drop off is completed");
        return "Your drop off is completed";
      case 13:
        return "Your valet is on the way for pickup";
      case 14:
        return "Your valet is near by location";
      case 15:
        return "Pickup completed";
      case 16:
        return "Pickup completed";
      case 17:
        return "Pickup completed";
      case 22:
        successToast("Your pickup is incomplete");

        return "Pickup incomplete";

      default:
        return bookingType === 1
          ? "Your Valet is assigned"
          : "Your Chauffeur is assigned";
    }
  })();

  const getStates = async () => {
    try {
      const res: any = await instance.get("");
      if (res?.data?.statusCode === 200) {
        setInitialData(res?.data?.data);
        setOrderStatus(res?.data?.data?.orderStatus);

        setDropOffDummy({
          latitude: res?.data?.data?.dropOf?.latitude,
          longitude: res?.data?.data?.dropOf?.longitude,
        });
        setPickUpDummy({
          latitude: res?.data?.data?.pickup?.latitude,
          longitude: res?.data?.data?.pickup?.longitude,
        });
        // if (res?.data?.data?.stageTwoDriverIdDetails) {
        //   setDriverLocation({
        //     latitude: res?.data?.data?.stageTwoDriverIdDetails?.latitude,
        //     longitude: res?.data?.data?.stageTwoDriverIdDetails?.longitude,
        //   });
        // } else if (res?.data?.data?.driverId) {
        //   setDriverLocation({
        //     latitude: res?.data?.data?.driverId?.latitude,
        //     longitude: res?.data?.data?.driverId?.longitude,
        //   });
        // }
      }
    } catch (error: any) {
      console.log(error, "error in api");
    }
  };

  async function calculateRouteForInitial() {
    if (dropOffDummy?.latitude === 0 || pickUpDummy?.latitude === 0) {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: { lat: pickUpDummy?.latitude, lng: pickUpDummy?.longitude },
      destination: {
        lat: dropOffDummy?.latitude,
        lng: dropOffDummy?.longitude,
      },
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    const points = decode(results.routes[0].overview_polyline);
    console.log("hitttttt");

    if (mapRef.current && points.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      points.forEach((point: any) => {
        if (Array.isArray(point)) {
          bounds.extend(new google.maps.LatLng(point[0], point[1]));
        } else {
          bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        }
      });
      mapRef.current.fitBounds(bounds, {
        padding: { top: 50, right: 50, bottom: 50, left: 50 }, // Add padding to ensure route is clearly visible
      });

      // Optional: Set map center explicitly
      const routeCenter = bounds.getCenter();
      mapRef.current.setCenter(routeCenter);
    }
    setInitialRouteCoordinates(points);
  }

  async function calculateRoute(abc: string | undefined) {
    if (dropofLocation?.latitude === 0 || driverLocation?.latitude === 0) {
      return;
    }
    // eslint-disable-next-line no-undef

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: { lat: driverLocation?.latitude, lng: driverLocation?.longitude },
      destination: {
        lat: dropofLocation?.latitude,
        lng: dropofLocation?.longitude,
      },
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    const points = decode(results.routes[0].overview_polyline);
    console.log("11111");
    if (mapRef.current && points.length > 0) {
      setRouteCoordinates(points);
      // mapRef.current.setCenter({driverLocation});
    }
  }

  const decode = (t: string, e = 5) => {
    let points = [];
    let index = 0,
      len = t.length;
    let lat = 0,
      lng = 0;

    while (index < len) {
      let shift = 0,
        result = 0;

      do {
        var b = t.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);

      lat += result & 1 ? ~(result >> 1) : result >> 1;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (shift = 0), (result = 0);

      do {
        var b = t.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);

      lng += result & 1 ? ~(result >> 1) : result >> 1;
      points.push({ lat: lat / 1e5, lng: lng / 1e5 });
    }
    return points;
  };

  const updatePolyline = (currentLocation: Geometry) => {
    const closestIndex = routeCoordinates.findIndex((coord) => {
      //@ts-ignore
      return haversineDistance(coord, currentLocation) < 0.05; // 100 meters threshold
    });

    if (closestIndex !== -1) {
      const updatedRoute = routeCoordinates.slice(closestIndex);

      setRouteCoordinates(updatedRoute);
      //   setInitialRouteCoordinates(updatedRoute);
    }
  };

  const haversineDistance = (
    point1: { latitude: any; longitude: any },
    point2: { latitude: number; longitude: number }
  ) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (point2.latitude - point1.latitude) * (Math.PI / 180);
    const dLon = (point2.longitude - point1.longitude) * (Math.PI / 180);
    const lat1 = point1.latitude * (Math.PI / 180);
    const lat2 = point2.latitude * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  useEffect(() => {
    // if (accessToken) {
    setToStorage(STORAGE_KEYS.token, accessToken);
    // }
  }, []);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (socketStatus === 15 || socketStatus === 5 || socketStatus === 12) {
      setIsPickUpComplete(true);
      console.log("ab hoga route change ---------------->>>>>>>>>>");
    } else {
      setIsPickUpComplete(false);
    }
  }, [socketStatus]);

  useEffect(() => {
    socket.emit("joinRoom", { bookingId: bookingId });
    socket?.on("joinRoomOk", (value: any) => {
      //   console.log(value, "event is here");
    });

    socket?.on("orderStatusUpdated", (value: any) => {
      setSocketStatus(value?.data?.orderStatus);
      setOrderStatus(value?.data?.orderStatus);
      setBookingType(value?.data?.bookingUserType);
      setDetails(value?.data);
    });
    if (
      orderStatus === 1 ||
      orderStatus === 15 ||
      orderStatus === 16 ||
      orderStatus === 17 ||
      orderStatus === 12 ||
      orderStatus === 21 ||
      orderStatus === 9 ||
      orderStatus === 22
    ) {
      console.log(orderStatus, "sttttttttatus1111");

      // socket.off("updateLocationOk");

      setDriverLocation({
        latitude: 0,
        longitude: 0,
      });
    } else {
      console.log(orderStatus, "sttttttttatus2222");

      socket?.on("updateLocationOk", (value: any) => {
        try {
          console.log("kjkjkjkjkjkjkj");

          setDriverLocation({
            latitude: value.data.latitude,
            longitude: value.data.longitude,
          });
        } catch (error) {
          console.error("Error processing updateLocationOk event:", error);
        }
      });
    }
  }, [socket]);

  const hasDeviatedFromRoute = (
    currentLocation: any,
    routeCoordinates: any
  ) => {
    const thresholdDistance = 0.2; // Threshold in kilometers
    for (const point of routeCoordinates) {
      if (haversineDistance(currentLocation, point) < thresholdDistance) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (
      routeCoordinates?.length &&
      driverLocation?.latitude !== 0 &&
      driverLocation?.longitude !== 0 &&
      dropofLocation?.latitude !== 0 &&
      dropofLocation?.longitude !== 0 &&
      orderStatus !== 5 &&
      orderStatus !== 6 &&
      orderStatus !== 7
    ) {
      // if (hasDeviatedFromRoute(driverLocation, routeCoordinates)) {
      //   console.log(
      //     "User has deviated from the route. Fetching a new route..."
      //   );

      //   calculateRoute("hjhjhjhjhjhjhjhj"); // Fetch a new route if deviated
      // } else {
      updatePolyline(driverLocation);
      // }
    }
  }, [driverLocation, dropofLocation]);

  // useEffect(() => {
  //   if (!isPickUpComplete) {
  //     setPickupLocation(driverLocation);
  //     setDropofLocation(pickupLocation);
  //   }
  // }, [isPickUpComplete]);

  useEffect(() => {
    if (routeCoordinates?.length > 1) {
      updatePolyline(driverLocation);
      // if (
      //   driverLocation?.latitude &&
      //   driverLocation?.longitude &&
      //   dropofLocation?.latitude &&
      //   dropofLocation?.longitude
      // ) {
      //   const updatedDis = haversineDistance(dropofLocation, driverLocation);
      //   const roundedDistance = parseFloat(updatedDis.toFixed(1));
      //   const formattedDistanceText =
      //     roundedDistance % 1 === 0
      //       ? `${Math.round(roundedDistance)} km`
      //       : `${roundedDistance} km`;
      //   setDistance1(formattedDistanceText);
      // }
    }
  }, [driverLocation, dropofLocation]);

  useEffect(() => {
    if (
      driverLocation?.latitude !== 0 &&
      driverLocation?.longitude !== 0 &&
      dropofLocation?.latitude !== 0 &&
      dropofLocation?.longitude !== 0
    ) {
      calculateRoute("nmnnmnmnm");
    }
  }, [dropofLocation]);

  useEffect(() => {
    if (
      (!driverLocation?.latitude &&
        !driverLocation?.longitude &&
        pickUpDummy?.latitude !== 0 &&
        pickUpDummy?.longitude !== 0 &&
        dropOffDummy?.latitude !== 0 &&
        dropOffDummy?.longitude !== 0) ||
      orderStatus === 1 ||
      orderStatus === 15 ||
      orderStatus === 16 ||
      orderStatus === 17 ||
      orderStatus === 12 ||
      orderStatus === 21 ||
      orderStatus === 9 ||
      orderStatus === 22
    ) {
      calculateRouteForInitial();
    }
  }, [pickUpDummy, dropOffDummy, orderStatus]);

  const checkOrderStatus = () => {
    if (
      orderStatus === 12 ||
      orderStatus === 8 ||
      orderStatus === 21 ||
      orderStatus === 6 ||
      orderStatus === 22 ||
      orderStatus === 15 ||
      orderStatus === 16 ||
      orderStatus === 17
    ) {
      // setShowDriver(true);
      setDriverLocation({
        latitude: 0,
        longitude: 0,
      });
      setDropofLocation({
        latitude: 0,
        longitude: 0,
      });
      // setStage(1);
    } else if (
      orderStatus === 13 ||
      orderStatus === 14 ||
      orderStatus === 2 ||
      orderStatus === 3 ||
      orderStatus === 4 ||
      orderStatus === 1
    ) {
      // setShowDriver(true);
      setDriverLocation({
        latitude: initialData?.driverId?.latitude,
        longitude: initialData?.driverId?.longitude,
      });
      setDropofLocation({
        latitude: initialData?.pickup?.latitude,
        longitude: initialData?.pickup?.longitude,
      });
      // setStage(2);
    } else if (orderStatus === 9 || orderStatus === 11 || orderStatus === 10) {
      // setShowDriver(true);
      setDriverLocation({
        latitude: initialData?.driverId?.latitude,
        longitude: initialData?.driverId?.longitude,
      });
      setDropofLocation({
        latitude: initialData?.dropOf?.latitude,
        longitude: initialData?.dropOf?.longitude,
      });
      // setStage(3);
    } else if (orderStatus === 7 || orderStatus === 5) {
      // setShowDriver(false);
      setDriverLocation({
        latitude: initialData?.pickup?.latitude,
        longitude: initialData?.pickup?.longitude,
      });
      setDropofLocation({
        latitude: initialData?.dropOf?.latitude,
        longitude: initialData?.dropOf?.longitude,
      });
      // setStage(4);
    }
    // else {
    //   setShowDriver(false);
    // }
  };

  useEffect(() => {
    checkOrderStatus();
  }, [orderStatus]);

  return (
    <Layout>
      <main className="content">
        <section className="tracking_sc">
          <div className="lt_s">
            <div>
              {routeCoordinates || initialRouteCoordinates ? (
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={12}
                  center={
                    driverLocation.latitude !== 0 &&
                    driverLocation.longitude !== 0
                      ? {
                          lat: driverLocation.latitude,
                          lng: driverLocation.longitude,
                        }
                      : undefined
                  }
                  onLoad={onMapLoad}
                  ref={mapRef}
                  options={{
                    gestureHandling: "auto",
                    fullscreenControl: true,
                    zoomControl: true,
                    mapTypeControl: true,
                    streetViewControl: true,
                    scaleControl: true,
                    rotateControl: true,
                  }}
                >
                  {/* {socketStatus === 8 || socketStatus === 17 || socketStatus === 21 ? ("") : ( */}
                  <>
                    <MarkerF
                      position={{
                        lat:
                          (driverLocation?.latitude === 0 &&
                            driverLocation?.longitude === 0) ||
                          orderStatus === 12
                            ? pickUpDummy.latitude
                            : driverLocation?.latitude,
                        lng:
                          (driverLocation?.latitude === 0 &&
                            driverLocation?.longitude === 0) ||
                          orderStatus === 12
                            ? pickUpDummy.longitude
                            : driverLocation?.longitude,
                      }}
                      icon={customPickupIcon}
                      title="Pickup Location"
                    />
                    <MarkerF
                      position={{
                        lat:
                          (driverLocation?.latitude === 0 &&
                            driverLocation?.longitude === 0) ||
                          orderStatus === 12
                            ? dropOffDummy.latitude
                            : dropofLocation?.latitude,
                        lng:
                          (driverLocation?.latitude === 0 &&
                            driverLocation?.longitude === 0) ||
                          orderStatus === 12
                            ? dropOffDummy.longitude
                            : dropofLocation?.longitude,
                      }}
                      title="Drop of Location"
                      icon={customDriverIcon}
                    />
                  </>

                  {
                    <PolylineF
                      path={
                        (!driverLocation?.latitude &&
                          !driverLocation?.longitude) ||
                        orderStatus === 12
                          ? initialRouteCoordinates
                          : routeCoordinates
                      }
                    />
                  }
                </GoogleMap>
              ) : null}
            </div>
          </div>
          <div className="rt_s">
            <h1>Tracking Detail</h1>
            <p className="booking_no">
              Booking Id : {initialData?.bookingID || ""}
            </p>
            <ul className="track_detail">
              {initialData?.driverId ? (
                <li>
                  <span>
                    {bookingType === 1 ? "Valet" : "Chauffeur"} Details:
                  </span>
                  <figure className="image_flex">
                    <img
                      src={
                        initialData?.driverId?.image
                          ? initialData?.driverId?.image
                          : `/assets/images/user_placeholder.png`
                      }
                      alt="Image"
                    />
                    <figcaption>
                      <strong>
                        {initialData?.driverId?.firstName
                          ? initialData?.driverId?.firstName
                          : "" + " " + initialData?.driverId?.lastName
                          ? initialData?.driverId?.lastName
                          : ""}
                      </strong>
                      <span>
                        {initialData?.driverId?.dialCode
                          ? initialData?.driverId?.dialCode
                          : ""}
                        {initialData?.driverId?.phoneNo
                          ? initialData?.driverId?.phoneNo
                          : ""}
                      </span>
                      <span>
                        {initialData?.driverId?.email
                          ? initialData?.driverId?.email
                          : ""}
                      </span>
                    </figcaption>
                  </figure>
                  <a
                    className="call_to"
                    href={`tel:${initialData?.driverId?.dialCode}${initialData?.driverId?.phoneNo}`}
                  >
                    <img src="/assets/images/phone_icon.svg" alt="Phone Icon" />
                  </a>
                </li>
              ) : null}

              <li>
                <span>Pickup:</span>
                <strong>{initialData?.pickup?.address || "-"}</strong>
              </li>
              <li>
                <span>Drop Off:</span>
                <strong>{initialData?.dropOf?.address || "_"}</strong>
              </li>
            </ul>
            {initialData?.driverId ? (
              <ul className="track_status">
                <li className="active">
                  <strong>{title || ""}</strong>
                </li>
              </ul>
            ) : null}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Tracking;
