import Header from "./header";

export const Layout = ({ children }: { children: any }) => {
    return (
        <>
            <Header />
            {children}
        </>
    );
};
