import React, { useEffect } from "react";
import "./App.scss";
import Routes from "./Routes";
import { socket } from "./utils/socket";
import { getFromStorage } from "./constant/storage";
import { STORAGE_KEYS } from "./constant/storageKeys";

const App = () => {
  const Socket_URL = "https://esqyreopreationapi.appgrowthcompany.com/";
  const socketToken: string = (getFromStorage(STORAGE_KEYS.token) ||
    "") as string;

  function onConnect() {
    console.log("connected");
  }
  function onDisconnect() {
    console.log("disconnected");
  }
  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  useEffect(() => {
    if (socketToken?.length) {
      const modifiedURL = `${Socket_URL}`;

      socket.io.opts.query = { token: socketToken };
      // socket.io.uri = modifiedURL;
      if (!socket?.connected) {
        socket.connect();
      }
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);
      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    } else {
      socket.disconnect();
    }
  }, [socketToken]);
  return <Routes />;
};

export default App;
