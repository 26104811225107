/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

const Header = () => {

    useEffect(() => {
        if (window) {
            window.scrollTo({ top: 0, behavior: "auto" });
        }
    }, []);

    return (
        <header className="site_header">
            <figure><img src={`/assets/images/logo.svg`} alt="Esqyre Logo" /></figure>
        </header >
    )
}

export default Header;
